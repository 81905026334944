<template>
    <v-container >
        <v-card  class="glass">
            <v-card-title>
                Tareas

                <v-spacer></v-spacer>

                <v-progress-circular :value="progress" class="mr-2" v-if="!loading && tasks.length > 0">
                    <template v-slot:default>
                        <strong style="font-size: 8px;">{{ progress && progress.toFixed(0) || 0 }}%</strong>
                    </template>
                </v-progress-circular>
                <v-progress-circular indeterminate class="mr-2" v-if="loading"></v-progress-circular>

            </v-card-title>
            <v-card-text>
                <v-text-field  v-model="newTask" label="Agregar tarea" filled rounded @keydown.enter="create" dense hide-details=""
                    class="rounded-lg">
                    <template v-slot:append>
                       <v-icon v-show="newTask && newTask.length>0" @click="create">
                                mdi-plus-circle
                       </v-icon>
                    </template>
                </v-text-field>


                <v-row class="my-1" align="center">


                    <v-tabs v-model="tabs" :show-arrows="$vuetify.breakpoint.smAndDown">
                        <v-tab>
                            <v-icon left>mdi-format-list-checks</v-icon>
                            <span>Pendientes</span>
                            <v-chip class="ml-4" >
                                {{ remainingTasks }}
                            </v-chip>
                        </v-tab>

                        <v-tab>
                            <v-icon left color="success">mdi-check-all</v-icon>
                            <span>Completadas</span>
                            <v-chip class="ml-4" >
                                {{ completedTasks }}
                            </v-chip>
                        </v-tab>

                        <v-tab-item>
                                                        <v-divider/>

                            <v-card-text>
                                <v-alert v-if="!loading && tasks.length == 0" type="info" text class="mt-4">
                                    No hay tareas pendientes.
                                </v-alert>

                                <template v-for="(task, i) in returnTasksNotDone">
                            <v-divider v-if="i !== 0" :key="`${i}-divider`" class="my-0
                        "></v-divider>

                            <v-list-item :key="`${i}-${task.text}`">
                                <v-list-item-action>
                                    <v-checkbox v-model="task.done" :color="task.done && 'grey' || 'primary'"
                                        @click="toggleTaskStatus(task)">

                                    </v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content v-text="task.text" :class="'grey--text'">

                                </v-list-item-content>

                                <v-scroll-x-transition>




                                    <v-icon v-if="task.done" color="success">
                                        mdi-check
                                    </v-icon>
                                    <v-btn v-else fab icon x-small color="error" class="ml-2"
                                        @click="deleteTask(task.id)">
                                        <v-icon> mdi-close</v-icon>
                                    </v-btn>


                                </v-scroll-x-transition>
                            </v-list-item>
                        </template>


                            </v-card-text>
                        </v-tab-item>

                        <v-tab-item>
                            <v-divider/>
                            <v-card-text>
                                <v-alert v-if="!loading && tasks.length == 0" type="info" text class="mt-4">
                                    No hay tareas completadas.
                                </v-alert>


                                 <template v-for="(task, i) in returnTasksDone">
                            <v-divider v-if="i !== 0" :key="`${i}-divider`" class="my-0
                        "></v-divider>

                            <v-list-item :key="`${i}-${task.text}`">
                                <v-list-item-action>
                                    <v-checkbox v-model="task.done" :color="task.done && 'grey' || 'primary'"
                                        @click="toggleTaskStatus(task)">

                                    </v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content v-text="task.text" :class="'grey--text'">

                                </v-list-item-content>

                                <v-scroll-x-transition>




                                    <v-icon v-if="task.done" color="success">
                                        mdi-check
                                    </v-icon>
                                    <v-btn v-else fab icon x-small color="error" class="ml-2"
                                        @click="deleteTask(task.id)">
                                        <v-icon> mdi-close</v-icon>
                                    </v-btn>


                                </v-scroll-x-transition>
                            </v-list-item>
                        </template>
                            </v-card-text>
                        </v-tab-item>

                    </v-tabs>

                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
// Import the Firebase utilities
import { getFirestore, collection, addDoc, query, onSnapshot, doc, updateDoc, deleteDoc } from 'firebase/firestore';

export default {
    data() {
        return {
            newTask: null,
            tasks: [],
            loading: true,
            // true to show only not done tasks and false to show only done tasks
            view: true,
            tabs: 0,

        }
    },

    computed: {
        completedTasks() {
            return this.tasks.filter(task => task.done).length
        },
        progress() {
            return this.completedTasks / this.tasks.length * 100
        },
        remainingTasks() {
            return this.tasks.length - this.completedTasks
        },
        returnTasksNotDone() {
            return this.tasks.filter(task => !task.done)
        },
         returnTasksDone() {
            return this.tasks.filter(task => task.done)
        }
    },

    methods: {
        async create() {
            if (!this.newTask) return;
            try {
                const db = getFirestore();

                await addDoc(collection(db, "tasks"), {
                    text: this.newTask,
                    done: false,
                    date: new Date(),
                    assignees: [],
                    createdBy: this.$store.state.Auth.token.claims.user_id,
                    completedBy: null
                });
                this.newTask = null;
            } catch (error) {
                console.error("Error adding document: ", error);
            }
        },
        async toggleTaskStatus(task) {
            const db = getFirestore();

            const taskRef = doc(db, "tasks", task.id);
            try {
                await updateDoc(taskRef, {
                    done: task.done,
                    completedBy: task.done && null || this.$store.state.Auth.token.claims.user_id
                });
            } catch (error) {
                console.error("Error updating document: ", error);
            }
        },
        async deleteTask(id) {

            const confirm = await this.$confirm('¿Realmente quieres eliminar esta tarea?',
                {
                    color: "error",
                    title: "Borrar tarea",
                    buttonTrueText: "CONFIRMAR",
                    buttonFalseText: "CANCELAR"
                }
            );

            if (!confirm) return;

            const db = getFirestore();

            const taskRef = doc(db, "tasks", id);
            try {
                await deleteDoc(taskRef);
            } catch (error) {
                console.error("Error deleting document: ", error);
            }
        }
    },

    mounted() {
        const db = getFirestore();

        const q = query(collection(db, "tasks"));
        onSnapshot(q, (querySnapshot) => {
            this.tasks = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            //sort tasks by date
            this.tasks.sort((a, b) => a.date - b.date);

            this.loading = false;
        });
    },
}
</script>
